import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ArchitectureCard,
  Button,
  FadeInSection,
  FeatureSet,
  Footer,
  Header,
  Subheading,
  CallToAction,
  FeatureSetProps,
} from '../components';
import {
  StyledCards,
  StyledCardsContainer,
  StyledContainer,
  StyledCTA,
  StyledFirstSection,
  StyledHeading,
} from '../styles';
import { ContainerProps } from '../types';
import { homeBg, homeFeature1, homeFeature2 } from '../images';

const cards = [
  {
    title: 'Realistic architectural rendering',
    description:
      'Tired of costly, inaccurate mock-ups leading to misinformed decisions and dissatisfied customers? RCAD extracts design specifications directly from your uploaded CAD drawings, making our models the most scale-precise digital renditions in the market.',
  },
  {
    title: 'Unforgettable virtual experiences',
    description:
      'Using industry-leading technology, RCAD provides compelling To-Scale experiences for your designers and customers. With fully rendered models, complete with ultra-realistic textures, scale and lighting, an RCAD VR headset lets you experience your design as if it was already built.',
  },
  {
    title: 'Interactive inventory catalogue',
    description:
      'We work closely with our clients to configure a bespoke digital asset library from their specific catalogues, allowing users to transform their surroundings with a single click. Tailor everything from tiles, door handles, furniture, fixtures, paint colour – even which paintings to hang on the walls.',
  },
];

const features: FeatureSetProps[] = [
  {
    image: homeFeature1,
    imagePosition: 'left',
    subtitle: 'ACCURACY',
    title: 'Precision through programming',
    description: (
      <>
        <span>
          Ever had a customer say their virtual mock-up "didn't quite feel
          right"? RCAD's To-Scale models are generated directly from your
          uploaded CAD files, providing you with 1:1 scale and centimetre-level
          precision in architectural design.
        </span>
        <br />
        <br />
        <span>
          We bring a holistic approach to design and construction, working to
          ensure the models that RCAD generates are the closest thing you can
          show to the customer without literally building the house.
        </span>
      </>
    ),
  },
  {
    image: homeFeature2,
    imagePosition: 'right',
    subtitle: 'EXPERIENCE',
    title: (
      <span>
        Explore <br /> the possibilities
      </span>
    ),
    description: (
      <>
        <span>
          Use RCAD's VR headset to show your customers their new home with an
          immersive, virtual open home tour, letting them interact with your
          design in real-time.
        </span>
        <br />
        <br />
        <span>
          Users can customise the appearance of their design with a single
          click, customising everything from tiles to fixtures, fittings,
          finishes and furniture according to your preconfigured catalogue. Once
          finalised, you can even order straight from your suppliers from the
          RCAD platform!
        </span>
      </>
    ),
  },
];

function HomeBuildersContainer(props: ContainerProps) {
  const { title } = props;

  useEffect(() => {
    document.title = `R-CAD - ${title}`;
  }, [title]);

  return (
    <main>
      <Header />
      <section>
        <StyledFirstSection image={homeBg}>
          <StyledCTA>
            <StyledHeading size={150}>
              Home <br /> Builders
            </StyledHeading>
            <Subheading
              style={{ margin: 0 }}
              text="Enjoy a fully integrated catalogue of fixtures and finishes that you can customise in real-time, providing your customers with an immersive, interactive home buying experience."
            />
            <Link to="contact-us">
              <Button text="get started" />
            </Link>
          </StyledCTA>
        </StyledFirstSection>
        <StyledContainer>
          <FadeInSection>
            <StyledCardsContainer>
              <StyledHeading size={72} style={{ marginBottom: 140 }}>
                Let your customers <br /> experience their new home <br />—
                before you build it
              </StyledHeading>
              <StyledCards>
                {cards.map((card, idx) => (
                  <ArchitectureCard
                    key={card.title}
                    idx={idx}
                    title={card.title}
                    description={card.description}
                  />
                ))}
              </StyledCards>
            </StyledCardsContainer>
          </FadeInSection>

          <FadeInSection>
            <StyledHeading size={72} style={{ margin: '200px 0 130px 0' }}>
              The most realistic property <br /> visualisation software <br />
              in the market
            </StyledHeading>
          </FadeInSection>

          <FadeInSection>
            <>
              {features.map((feature) => (
                <FeatureSet
                  key={feature.subtitle}
                  title={feature.title}
                  description={feature.description}
                  image={feature.image}
                  imagePosition={feature.imagePosition}
                  subtitle={feature.subtitle}
                />
              ))}
            </>
          </FadeInSection>
        </StyledContainer>

        <FadeInSection>
          <CallToAction
            title="Ready to Get Started?"
            text="We work closely with you to ensure RCAD is the right fit for your business. Start providing unforgettable, interactive experiences for your customers today."
          />
        </FadeInSection>
        <FadeInSection>
          <Footer />
        </FadeInSection>
      </section>
    </main>
  );
}

export default HomeBuildersContainer;
