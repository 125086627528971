import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { learnMore } from '../images';

interface UsageCardProps {
  icon: string;
  positionIcon: string;
  title: JSX.Element;
  description: string;
}

const StyledButton = styled.button`
  border: none;
  background: transparent;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d9c494;
  display: flex;
  align-items: center;
  width: 180px;
  text-decoration: none;
  cursor: pointer;
  padding: 0;

  > span {
    display: block;
    margin-right: 14px;
    transition: all 200ms ease-in;
  }

  &:hover {
    > span {
      display: block;
      margin-right: 20px;
    }
  }
`;

const StyledDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.8;
  margin-top: 25px;
  margin-bottom: 20px;

  @media (max-width: 1440px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

const StyledTitle = styled.h1`
  font-family: 'Helvetica Neue Roman';
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  margin: 0;
  margin-top: 50px;
  min-height: 100px;

  @media (max-width: 1440px) {
    min-height: 40px;
    font-size: 20px;
    line-height: 24px;
  }
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 479px;
  height: 667px;
  align-items: flex-start;
  padding: 100px 30px 65px 80px;
  position: relative;
  background: linear-gradient(
    269.56deg,
    rgba(191, 175, 135, 0.05) 0.4%,
    rgba(191, 175, 135, 0) 99.65%
  );

  @media (max-width: 1440px) {
    width: 300px;
    height: 520px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const StyledPositionIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 40px;
`;

const StyledIcon = styled.img`
  min-height: 170px;
  height: 170px;

  @media (max-width: 1440px) {
    height: 150px;
    min-height: 150px;
  }
`;

function UsageCard(props: UsageCardProps) {
  const { icon, positionIcon, title, description } = props;

  return (
    <StyledCard>
      <StyledPositionIcon src={positionIcon} alt="" />
      <StyledIcon src={icon} alt="" />
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
      <Link to="/contact-us" style={{ textDecoration: 'none' }}>
        <StyledButton>
          <span>Learn More</span> <img src={learnMore} alt="Learn More" />
        </StyledButton>
      </Link>
    </StyledCard>
  );
}

export default UsageCard;
