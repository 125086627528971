import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { leftImage, mobileImage, rightImage } from '../images';
import Button from './Button';

const StyledHeading = styled.h1`
  color: #fff;
  font-family: 'Helvetica Neue Roman';
  font-size: 72px;
  line-height: 86px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 35px;

  @media (max-width: 1440px) {
    font-size: 46px;
    line-height: 60px;
    text-align: left;
  }
`;

const StyledDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.8;
  margin-top: 0;
  margin-bottom: 15px;
  width: 490px;

  @media (max-width: 900px) {
    width: auto;
    text-align: left;
  }
`;

const StyledContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 250px;

  @media (max-width: 1440px) {
    max-width: 900px;
    align-items: flex-start;
  }
`;

const StyledScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  @media (max-width: 900px) {
    padding: 0 5%;
  }
`;

const StyledCTAImage = styled.img<{
  alignment: 'left' | 'right';
}>`
  position: absolute;
  height: 900px;
  bottom: 0;
  z-index: -1;

  ${(props) =>
    props.alignment === 'left' &&
    `
  left: 0;
  `}

  ${(props) =>
    props.alignment === 'right' &&
    `
  right: 0;
  `}

  @media(max-width: 900px) {
    display: none;
  }
`;

const StyledCTAMobileImage = styled.img<{
  alignment: 'left' | 'right';
}>`
  display: none;
  position: absolute;
  bottom: 0;
  z-index: -1;

  ${(props) =>
    props.alignment === 'left' &&
    `
  left: 0;
  `}

  ${(props) =>
    props.alignment === 'right' &&
    `
  right: 0;
  `}

  @media(max-width: 900px) {
    display: block;
  }
`;

interface CallToActionProps {
  text: string | JSX.Element;
  title: string | JSX.Element;
}

function CallToAction(props: CallToActionProps) {
  const { text, title } = props;

  return (
    <StyledScreen>
      <StyledContainer>
        <StyledHeading>{title}</StyledHeading>
        <StyledDescription>{text}</StyledDescription>
        <Link to="/contact-us">
          <Button
            text="Step Into The Future"
            style={{ marginTop: 40, width: 240 }}
          />
        </Link>
      </StyledContainer>
      <StyledCTAImage alignment="left" src={leftImage} alt="" />
      <StyledCTAImage alignment="right" src={rightImage} alt="" />
      <StyledCTAMobileImage alignment="right" src={mobileImage} alt="" />
    </StyledScreen>
  );
}

export default CallToAction;
