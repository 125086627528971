import { useEffect } from 'react';
import styled from 'styled-components';
import { Footer, Header, SubmissionForm } from '../components';
import { contactBg } from '../images';

const StyledContainer = styled.section`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1113px;

  @media (max-width: 1113px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledHeading = styled.h1`
  color: #fff;
  font-family: 'Helvetica Neue Roman';
  font-size: 72px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 32px;

  @media (max-width: 900px) {
    font-size: 48px;
    margin-top: 100px;
  }
`;

const StyledSubtitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: #ffffff;
  opacity: 0.8;

  > p {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
`;

const StyledSubtitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 70px;
  }
`;

const StyledWrapper = styled.div`
  background: url(${contactBg});
  background-repeat: no-repeat;
  background-position: left top;
  padding: 110px 0;

  @media (max-width: 900px) {
    padding: 0 5%;
  }
`;

const StyledCTA = styled.div`
  margin-top: 150px;
  margin-right: 130px;

  @media (max-width: 1113px) {
    margin: 0;
  }
`;

function ContactUsContainer() {
  useEffect(() => {
    document.title = 'R-CAD - Contact Us';
  }, []);

  return (
    <>
      <Header />
      <StyledWrapper>
        <StyledContainer>
          <StyledCTA>
            <StyledHeading>
              We’d love to <br /> hear from you
            </StyledHeading>

            <StyledSubtitleContainer>
              <StyledSubtitle>
                <p>
                  <span>RCAD Pty Ltd</span>
                  <span>Brisbane Technology Park</span>
                  <span>Suite 17, 3 Clunies Ross Ct</span>
                  <span>Eight Mile Plains, QLD 4113, Australia</span>
                </p>
                <p>
                  <span>RCAD India Pty Ltd</span>
                  <span>#201, 2nd floor, Plot No.98B/146</span>
                  <span>Patrika Nagar, Madhapur, Hyderabad 500081</span>
                  <span>Telangana, India</span>
                </p>
              </StyledSubtitle>

              <StyledSubtitle>
                <p>
                  <span>sales@rcad.com</span>
                  <span>(+61) 431 162 706</span>
                  <span>(+91) 9059 225 259</span>
                </p>
              </StyledSubtitle>
            </StyledSubtitleContainer>
          </StyledCTA>

          <SubmissionForm />
        </StyledContainer>
      </StyledWrapper>
      <Footer />
    </>
  );
}

export default ContactUsContainer;
