import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { homeImage1, homeImage2 } from '../images';
import Subheading from './Subheading';

const StyledScreen = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  height: 100vh;

  @media (max-width: 900px) {
    position: relative;
    padding: 35px 5%;
    height: auto;
  }
`;

const StyledHeading = styled.h1`
  color: #fff;
  font-family: 'Helvetica Neue Roman';
  font-size: 60px;
  font-weight: normal;
  position: absolute;
  transform: translateY(-50%);
  margin: 0;
  margin-left: 180px;
  z-index: 1;

  @media (max-width: 1440px) {
    font-size: 46px;
    line-height: 60px;
  }

  @media (max-width: 990px) {
    margin: 0;
    padding: 0 5%;
  }

  @media (max-width: 900px) {
    opacity: 1 !important;
    position: initial;
    transform: none;
    padding: 0;
  }
`;

const StyledRelativeHeading = styled.h1`
  color: #fff;
  font-family: 'Helvetica Neue Roman';
  font-size: 60px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 70px;
  z-index: 1;

  @media (max-width: 1440px) {
    font-size: 46px;
    line-height: 60px;
  }

  @media (max-width: 990px) {
    margin: 0;
    padding: 0 5%;
  }
`;

const StyledBackground = styled.img`
  position: absolute;
  right: 0;
  bottom: 5%;

  @media (max-width: 1440px) {
    width: 1000px;
    bottom: 0;
  }

  @media (max-width: 1280px) {
    bottom: 0;
  }

  @media (max-width: 900px) {
    width: 100%;
    position: initial;
    margin: 17px 0;
    opacity: 1 !important;
  }
`;

const StyledSecondSection = styled.div`
  position: absolute;
  max-width: 700px;
  z-index: 1;
  margin: 60px 0 40px 180px;

  @media (max-width: 900px) {
    position: initial;
    margin: 60px 0 40px 0;
    opacity: 1 !important;

    > div {
      margin: 0;
      padding: 0 5%;
    }
  }
`;

function HomeIntro() {
  const domRef = useRef<HTMLDivElement | null>(null);
  const [isVisible, setVisible] = useState(true);
  const [scroll, setScroll] = useState(() => ({
    headingPosition: window.innerHeight / 2,
    image1Opacity: 1,
    image2Opacity: 0,
  }));

  useEffect(() => {
    const element = domRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  useLayoutEffect(() => {
    const onScroll = () => {
      const verticalScroll = window.scrollY;
      const windowHeight = window.innerHeight;

      setScroll({
        headingPosition: Math.max(200, windowHeight / 2 - verticalScroll),
        image1Opacity: 1 - verticalScroll / 200,
        image2Opacity: verticalScroll / 200,
      });
    };

    if (isVisible) {
      window.addEventListener('scroll', onScroll);
    } else {
      window.removeEventListener('scroll', onScroll);
    }

    return () => {
      if (isVisible) {
        window.removeEventListener('scroll', onScroll);
      }
    };
  }, [isVisible]);

  return (
    <StyledScreen ref={domRef}>
      <StyledHeading
        style={{
          top: `${scroll.headingPosition}px`,
          opacity: scroll.image1Opacity,
        }}
      >
        Create <br /> unforgettable <br /> digital experiences
      </StyledHeading>

      <StyledBackground
        style={{
          opacity: scroll.image1Opacity,
        }}
        src={homeImage1}
        alt=""
      />

      <StyledSecondSection
        style={{
          opacity: scroll.image2Opacity,
          top: `${scroll.headingPosition + 150}px`,
        }}
      >
        <StyledRelativeHeading>
          Automatically generate <br /> fully immersive, <br />
          centimetre-accurate <br /> 3D models from your CAD files
        </StyledRelativeHeading>
        <Subheading text="RCAD lets you deliver unparalleled customer experiences by transforming your 2D CAD drawings into dynamic, photo-realistic, VR-capable models." />
        <Subheading text="Save on design time and consulting fees by empowering your teams to create centimetre-accurate, fully-explorable rendered models of your land, property, or infrastructure designs. Real-time component customisation lets you realistically test the appearance of different assets in your designs." />
        <Subheading text="Improve project management and cost estimations through RACD's BIM and GIS integration, helping deliver projects on time and on budget." />
      </StyledSecondSection>

      <StyledBackground
        style={{
          opacity: scroll.image2Opacity,
        }}
        src={homeImage2}
        alt=""
      />
    </StyledScreen>
  );
}

export default HomeIntro;
