import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ArchitectureCard,
  Button,
  FadeInSection,
  FeatureSet,
  Footer,
  Header,
  Subheading,
  CallToAction,
  FeatureSetProps,
} from '../components';
import {
  StyledCards,
  StyledContainer,
  StyledHeading,
  StyledCardsContainer,
  StyledFirstSection,
  StyledCTA,
} from '../styles';
import { ContainerProps } from '../types';
import {
  architectsBg,
  architectsFeature1,
  architectsFeature2,
} from '../images';

const cards = [
  {
    title: 'Realistic architectural rendering',
    description:
      'Tired of costly, inaccurate mock-ups leading to misinformed decisions and dissatisfied customers? RCAD extracts design specifications directly from your uploaded CAD drawings, making our models the most scale-precise digital renditions in the market.',
  },
  {
    title: 'Unforgettable virtual experiences',
    description:
      'Using industry-leading technology, RCAD provides compelling To-Scale experiences for your designers and customers. With fully rendered models, complete with ultra-realistic textures, scale and lighting, an RCAD VR headset lets you experience your design as if it was already built.',
  },
  {
    title: 'Interactive inventory catalogue',
    description:
      'We work closely with our clients to configure a bespoke digital asset library from their specific catalogues, allowing users to transform their surroundings with a single click. Tailor everything from tiles, door handles, furniture, fixtures, paint colour – even which paintings to hang on the walls.',
  },
];

const features: FeatureSetProps[] = [
  {
    image: architectsFeature1,
    imagePosition: 'left',
    subtitle: 'TRANSFORMATION',
    title: (
      <span>
        The added dimension: <br /> CAD to RCAD
      </span>
    ),
    description: (
      <>
        <span>
          RCAD's intelligent programming automatically converts your uploaded 2D
          CAD drawing into a fully rendered model including designated
          structures, which you can then modify at your will.
        </span>
        <br />
        <br />
        <span>
          RCAD puts the power of design and rendering in the hands of the
          architect, meaning no more manual, expensive ad-hoc outsourcing to
          design firms; changes are made in real-time, letting you quickly test
          and assess ideas.
        </span>
      </>
    ),
  },
  {
    image: architectsFeature2,
    imagePosition: 'right',
    subtitle: 'EXPERIENCE',
    title: (
      <span>
        Photo-realistic, <br /> immersive experience
      </span>
    ),
    description: (
      <span>
        Use RCAD's modern VR headsets to realistically view your design through
        RCAD's 360° interactive exploration mode. Share it with your customers
        to blow them away with an inside look at their new home.
      </span>
    ),
  },
];

function ArchitectsContainer(props: ContainerProps) {
  const { title } = props;

  useEffect(() => {
    document.title = `R-CAD - ${title}`;
  }, [title]);

  return (
    <main>
      <Header />
      <section>
        <StyledFirstSection image={architectsBg}>
          <StyledCTA>
            <StyledHeading size={150}>Architects</StyledHeading>
            <Subheading
              style={{ margin: 0 }}
              text="Execute your vision to perfection by making design changes in real-time. RCAD interacts seamlessly with CAD files, reducing labour and improving cost estimation."
            />
            <Link to="contact-us">
              <Button text="get started" />
            </Link>
          </StyledCTA>
        </StyledFirstSection>

        <StyledContainer>
          <FadeInSection>
            <StyledCardsContainer>
              <StyledHeading size={72} style={{ marginBottom: 140 }}>
                Transform your architectural <br /> design process using <br />
                customisable visualisation
              </StyledHeading>
              <StyledCards>
                {cards.map((card, idx) => (
                  <ArchitectureCard
                    key={card.title}
                    idx={idx}
                    title={card.title}
                    description={card.description}
                  />
                ))}
              </StyledCards>
            </StyledCardsContainer>
          </FadeInSection>

          <FadeInSection>
            <StyledHeading size={72} style={{ margin: '200px 0 130px 0' }}>
              Create a design that <br /> exceeds your clients' <br />{' '}
              expectations
            </StyledHeading>
          </FadeInSection>

          <FadeInSection>
            <>
              {features.map((feature) => (
                <FeatureSet
                  key={feature.subtitle}
                  title={feature.title}
                  description={feature.description}
                  image={feature.image}
                  imagePosition={feature.imagePosition}
                  subtitle={feature.subtitle}
                />
              ))}
            </>
          </FadeInSection>
        </StyledContainer>
        <FadeInSection>
          <CallToAction
            title="Ready to Get Started?"
            text="Let's talk about how you can transform your architectural design process through automated digitalisation today."
          />
        </FadeInSection>
        <FadeInSection>
          <Footer />
        </FadeInSection>
      </section>
    </main>
  );
}

export default ArchitectsContainer;
