import styled from 'styled-components';
import Subheading from './Subheading';

export interface FeatureProps {
  image: string;
  imagePosition: 'left' | 'right';
  subtitle: string;
  title: string | JSX.Element;
  description: string | JSX.Element;
}

const StyledFeatureSet = styled.div`
  display: flex;
  margin-bottom: 200px;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @media (max-width: 900px) {
    margin-bottom: 90px;

    > img {
      order: 0 !important;
    }
  }

  > img {
    @media (max-width: 765px) {
      width: 100%;
    }
  }
`;

const StyledSubtitle = styled.h3`
  font-family: 'Helvetica Neue Roman';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d9c494;
  margin: 0;
  margin-bottom: 40px;

  @media (max-width: 900px) {
    text-align: left;
    margin-bottom: 30px;
  }
`;

const StyledTitle = styled.h2`
  font-family: 'Helvetica Neue Roman';
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 86px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 60px;

  @media (max-width: 900px) {
    font-size: 48px;
    line-height: 58px;
    text-align: left;
    margin-bottom: 60px;
  }
`;

const StyledCard = styled.div<{
  imagePosition: 'left' | 'right';
}>`
  margin: ${(props) =>
    props.imagePosition === 'left' ? '0 0 0 140px' : '0 140px 0 0'};

  @media (max-width: 1200px) {
    margin: 0;
    margin-bottom: ${(props) => (props.imagePosition === 'right' ? 50 : 0)}px;
  }
`;

function FeatureSet(props: FeatureProps) {
  const { image, imagePosition, subtitle, title, description } = props;

  return (
    <StyledFeatureSet>
      <img
        src={image}
        alt={subtitle}
        style={{ order: imagePosition === 'left' ? 0 : 1 }}
      />

      <StyledCard
        imagePosition={imagePosition}
        style={{
          order: imagePosition === 'left' ? 1 : 0,
        }}
      >
        <StyledSubtitle>{subtitle}</StyledSubtitle>
        <StyledTitle>{title}</StyledTitle>
        <Subheading text={description} style={{ margin: 0 }} />
      </StyledCard>
    </StyledFeatureSet>
  );
}

export default FeatureSet;
