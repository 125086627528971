import { useEffect, useRef, useState } from 'react';

interface FadeInSectionProps {
  children: JSX.Element;
}

function FadeInSection(props: FadeInSectionProps) {
  const { children } = props;

  const [isVisible, setVisible] = useState(true);
  const domRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const element = domRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, []);

  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {children}
    </div>
  );
}

export default FadeInSection;
