import { ChangeEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Select, { StylesConfig } from 'react-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/plain.css';
import Button from './Button';
import { checkIcon } from '../images';
import { rawCountries, Country, getCountryNameFromCode } from '../countries';

const StyledFormContainer = styled.form`
  width: 488px;
  background: #3d3d3f;
  padding: 55px 64px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const StyledInput = styled.input`
  background: #313133;
  border: 1px solid #49494d;
  width: 360px;
  height: 50px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #fff;
  padding-left: 12px;
  margin-top: 4px;
  outline: none;

  &::placeholder {
    color: rgba(191, 191, 191, 0.5);
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const StyledLabel = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 24px;
  display: block;
`;

const StyledTextarea = styled.textarea`
  background: #313133;
  border: 1px solid #49494d;
  padding: 12px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  resize: none;
  width: 360px;
  margin-top: 4px;
  outline: none;
  color: #fff;

  &::placeholder {
    color: rgba(191, 191, 191, 0.5);
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const StyledTerms = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  margin-top: 30px;

  > a {
    color: #bfaf87;
    text-decoration: none;
  }
`;

const customStyles = (hasError: boolean) =>
  ({
    option: (
      provided: object,
      state: {
        isFocused: boolean;
        isSelected: boolean;
      }
    ) => {
      const { isFocused, isSelected } = state;

      return {
        ...provided,
        width: 360,
        height: 40,
        color: '#fff',
        background: isFocused ? '#212121' : '#313133',
        cursor: 'pointer',
        fontSize: 16,
        lineHeight: '24px',
        paddingLeft: 40,
        position: 'relative',
        '&:before': isSelected
          ? {
              content: `url(${checkIcon})`,
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              left: 13,
              display: 'block',
            }
          : {},
      };
    },
    menu: (provided: object) => ({
      ...provided,
      background: '#313133',
      boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.3)',
      borderRadius: 1,
    }),
    input: (provided: object) => ({
      ...provided,
      cursor: 'pointer',
      outline: 'none',
      color: '#fff',
    }),
    placeholder: (provided: object) => ({
      ...provided,
      color: 'rgba(191,191,191,0.5)',
    }),
    singleValue: (provided: object) => ({
      ...provided,
      color: '#fff',
    }),
    indicatorSeparator: (provided: object) => ({
      ...provided,
      display: 'none',
    }),
    dropdownIndicator: (provided: object) => ({
      ...provided,
      color: 'rgba(191,191,191,0.5)',
    }),
    control: (provided: object) => ({
      ...provided,
      background: '#313133',
      border: `1px solid ${hasError ? 'red' : '#49494D'}`,
      borderRadius: 0,
      width: 360,
      height: 50,
      marginTop: 4,
      boxShadow: 'none',
      '&:hover': {
        border: `1px solid ${hasError ? 'red' : '#49494D'}`,
        boxShadow: 'none',
      },
      '&:active': {
        border: `1px solid ${hasError ? 'red' : '#49494D'}`,
        boxShadow: 'none',
      },

      '@media (max-width: 900px)': {
        width: '100%',
      },
    }),
  } as StylesConfig<any, any, any>);

const StyledErrorMessage = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ff6550;
`;
interface SelectOption {
  value: string;
  label: string;
}

const options: SelectOption[] = [
  { value: 'Architects', label: 'Architects' },
  { value: 'Home-builders', label: 'Home-builders' },
  { value: 'Land Developers', label: 'Land Developers' },
  { value: 'High-rise Developers', label: 'High-rise Developers' },
  { value: 'Infrastructure Developers', label: 'Infrastructure Developers' },
];

function SubmissionForm() {
  const [selectedOption, setSelectedOption] = useState<SelectOption>();
  const [country, setCountry] = useState<string>();

  const [errors, setErrors] = useState({
    area: '',
    name: '',
    phone: '',
    email: '',
    country: '',
  });

  useEffect(() => {
    if (selectedOption !== undefined) {
      setErrors({ ...errors, area: '' });
    }

    if (country !== undefined) {
      setErrors({ ...errors, country: '' });
    }
  }, [selectedOption, country]); // eslint-disable-line

  const handleMenuClose = (type: 'area-of-usage' | 'country') => {
    if (selectedOption === undefined && type === 'area-of-usage') {
      setErrors({ ...errors, area: 'Please select the area of usage.' });
    }

    if (country === undefined && type === 'country') {
      setErrors({ ...errors, country: 'Please select the country.' });
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { name, value } = event.target;

    if (value.length < 1) {
      setErrors({ ...errors, [name]: `Please enter your ${name}` });
    } else {
      setErrors({ ...errors, [name]: '' });
    }
  };

  return (
    <StyledFormContainer name="contact" method="post">
      <input type="hidden" name="form-name" value="contact" />

      <StyledLabel>
        Area of usage
        <Select
          styles={customStyles(errors.area !== '')}
          options={options}
          name="area-of-usage"
          placeholder="Select the area of usage"
          onMenuClose={() => handleMenuClose('area-of-usage')}
          value={selectedOption}
          onChange={(newValue) => {
            if (newValue) {
              setSelectedOption(newValue);
            }
          }}
        />
        {errors.area.length > 0 && (
          <StyledErrorMessage>{errors.area}</StyledErrorMessage>
        )}
        <StyledInput
          style={{ display: 'none' }}
          value={selectedOption?.value}
          type="text"
          name="area-of-usage"
          placeholder="Select the area of usage"
          required
          onChange={handleChange}
        />
      </StyledLabel>

      <StyledLabel>
        Name
        <StyledInput
          type="text"
          name="name"
          placeholder="Enter your name"
          required
          onChange={handleChange}
        />
        {errors.name.length > 0 && (
          <StyledErrorMessage>{errors.name}</StyledErrorMessage>
        )}
      </StyledLabel>

      <StyledLabel>
        Country
        <Select
          styles={customStyles(errors.country !== '')}
          options={rawCountries}
          name="country"
          placeholder="Select the country"
          onMenuClose={() => handleMenuClose('country')}
          onChange={(newValue: Country) => {
            setCountry(newValue.value);
          }}
        />
        {errors.country.length > 0 && (
          <StyledErrorMessage>{errors.country}</StyledErrorMessage>
        )}
        <StyledInput
          style={{ display: 'none' }}
          value={getCountryNameFromCode(country)}
          type="text"
          name="country"
          placeholder="Select the country"
          required
          onChange={handleChange}
        />
      </StyledLabel>
      <StyledLabel>
        Phone
        <PhoneInput
          countryCodeEditable={false}
          country={country ?? 'us'}
          inputProps={{
            name: 'phone',
            placeholder: 'Enter your phone',
            required: true,
          }}
          showDropdown={false}
          disableDropdown={true}
          inputClass="contact-form-dropdown-input"
          buttonClass="contact-form-dropdown-button"
          dropdownClass="contact-form-dropdown"
        />
      </StyledLabel>

      <StyledLabel>
        Email
        <StyledInput
          type="email"
          name="email"
          placeholder="Enter your email"
          required
          onChange={handleChange}
        />
        {errors.email.length > 0 && (
          <StyledErrorMessage>{errors.email}</StyledErrorMessage>
        )}
      </StyledLabel>

      <StyledLabel>
        Message
        <StyledTextarea
          rows={4}
          name="message"
          placeholder="Type your message..."
        />
      </StyledLabel>

      <Button
        style={{
          width: '100%',
        }}
        text="Get free demo"
        type="submit"
      />

      <StyledTerms>
        By clicking "Get free demo" I agree to the{' '}
        <Link to="/privacy-policy">Privacy Policy</Link> and{' '}
        <Link to="/terms-of-use">Terms of Use</Link>.
      </StyledTerms>
    </StyledFormContainer>
  );
}

export default SubmissionForm;
