import styled from 'styled-components';
import { NavigationItem } from '../types';
import NavItem from './NavItem';

const navItems: NavigationItem[] = [
  {
    inverted: false,
    title: 'solutions',
    href: '',
    navItems: [
      { inverted: false, title: 'Architects', href: '/solutions/architects' },
      {
        inverted: false,
        title: 'Home-Builders',
        href: '/solutions/home-builders',
      },
      {
        inverted: false,
        title: 'Land Developers',
        href: '/solutions/land-developers',
      },
      {
        inverted: false,
        title: 'High-Rise Developers',
        href: '/solutions/high-rise-developers',
      },
      {
        inverted: false,
        title: 'Civil Infrastructure',
        href: '/solutions/civil-infrastructure',
      },
    ],
  },
  { inverted: false, title: 'about', href: '/about' },
  { inverted: true, title: 'get started', href: '/contact-us' },
];

const StyledNavigation = styled.nav`
  display: flex;

  @media (max-width: 1066px) {
    display: none;
  }
`;

function Navigation() {
  return (
    <StyledNavigation>
      {navItems.map((navItem) => (
        <NavItem
          key={navItem.href}
          href={navItem.href}
          inverted={navItem.inverted}
          title={navItem.title}
          navItems={navItem.navItems}
        />
      ))}
    </StyledNavigation>
  );
}

export default Navigation;
