import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { facebookIcon, instagramIcon, linkedInIcon, logo } from '../images';

const solutions = [
  {
    title: 'Home Builders',
    href: '/solutions/home-builders',
  },
  {
    title: 'Land Developers',
    href: '/solutions/land-developers',
  },
  {
    title: 'High-Rise Builders',
    href: '/solutions/high-rise-developers',
  },
  {
    title: 'Civil Infrastructure',
    href: '/solutions/civil-infrastructure',
  },
  {
    title: 'Architects',
    href: '/solutions/architects',
  },
];

const StyledMetadata = styled.div`
  width: 100%;
  height: 282px;
  border-top: 0.5px solid #938880;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px 5%;
    align-items: flex-start;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;

      > div {
        text-align: left;
        align-items: flex-start;
        width: 100%;
      }
    }
  }
`;

const StyledMetadataContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    max-width: 900px;
  }
`;

const StyledCopyright = styled.p`
  font-family: 'Helvetica Neue Roman';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  margin-top: 30px;

  @media (max-width: 900px) {
    margin-top: 20px;
  }
`;

const StyledSolutions = styled.div`
  display: flex;
  flex-direction: column;

  > a {
    text-decoration: none;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  @media (max-width: 900px) {
    order: 0;
    align-items: flex-start;

    > a {
      width: 100%;
      color: rgba(255, 255, 255, 0.8);
      border-bottom: 1px solid rgba(147, 136, 128, 0.15);
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 10px;
    }
  }
`;

const StyledFooterHeading = styled.h4<{
  showXS?: boolean;
}>`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 12px;
  text-decoration: none;

  ${(props) =>
    props.showXS === true &&
    `
  display: none;

  @media(max-width: 900px) {
    display: block;
  }
  `};

  ${(props) =>
    props.showXS === false &&
    `
  display: block;

  @media(max-width: 900px) {
    display: none;
  }
  `};
`;

const StyledSocialMedia = styled.div<{
  hiddenXS?: boolean;
  showXS?: boolean;
}>`
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    order: 2;
  }

  ${(props) =>
    props.hiddenXS === true &&
    `
    @media (max-width: 900px) {
    display: none;
  }
  `}

  ${(props) =>
    props.showXS === true &&
    `

    display: none;
    @media (max-width: 900px) {
    display: block;
  }
  `}
`;

const StyledLogoContainer = styled.div`
  @media (max-width: 900px) {
    order: 1;
  }
`;

const StyledSocialMediaIcon = styled.img`
  @media (max-width: 900px) {
    margin-right: 15px !important;
    width: 24px;
    height: 24px;
  }
`;

const StyledLogo = styled.img`
  @media (max-width: 900px) {
    height: 30px;
  }
`;

function Footer() {
  return (
    <StyledMetadata>
      <StyledMetadataContainer>
        <StyledLogoContainer>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Link to="/">
              <StyledLogo src={logo} alt="Logo" />
            </Link>
            <StyledSocialMedia showXS>
              <StyledFooterHeading showXS={false}>
                Follow Us
              </StyledFooterHeading>

              <div>
                <a
                  href="https://www.instagram.com/_.rcad_/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledSocialMediaIcon
                    src={instagramIcon}
                    alt="Instagram"
                    style={{ marginRight: 25 }}
                  />
                </a>
                <a
                  href="https://www.facebook.com/r.cad.756"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledSocialMediaIcon
                    src={facebookIcon}
                    alt="Facebook"
                    style={{ marginRight: 25 }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/get-rcad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <StyledSocialMediaIcon src={linkedInIcon} alt="LinkedIn" />
                </a>
              </div>
            </StyledSocialMedia>
          </div>

          <StyledCopyright>© 2021 RCAD. All rights reserved</StyledCopyright>
        </StyledLogoContainer>

        <StyledSolutions>
          <StyledFooterHeading>Solutions</StyledFooterHeading>

          {solutions.map((solution) => (
            <Link
              key={solution.href}
              to={solution.href}
              style={{
                color: 'rgba(255, 255, 255, 0.7)',
              }}
            >
              {solution.title}
            </Link>
          ))}
        </StyledSolutions>

        <Link
          to="/about"
          style={{ textDecoration: 'none', marginBottom: 50, marginTop: 20 }}
        >
          <StyledFooterHeading showXS>About</StyledFooterHeading>
        </Link>

        <StyledSocialMedia hiddenXS>
          <StyledFooterHeading showXS={false}>Follow Us</StyledFooterHeading>

          <div>
            <a
              href="https://www.instagram.com/_.rcad_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledSocialMediaIcon
                src={instagramIcon}
                alt="Instagram"
                style={{ marginRight: 25 }}
              />
            </a>
            <a
              href="https://www.facebook.com/r.cad.756"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledSocialMediaIcon
                src={facebookIcon}
                alt="Facebook"
                style={{ marginRight: 25 }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/get-rcad"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StyledSocialMediaIcon src={linkedInIcon} alt="LinkedIn" />
            </a>
          </div>
        </StyledSocialMedia>
      </StyledMetadataContainer>
    </StyledMetadata>
  );
}

export default Footer;
