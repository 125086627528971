import { useEffect } from 'react';
import styled from 'styled-components';
import {
  CallToAction,
  Footer,
  Header,
  FadeInSection,
  ArchitectureCard,
  Subheading,
} from '../components';
import { aboutBg, cta } from '../images';

const StyledImage = styled.img`
  width: 100%;
  margin-bottom: 200px;
`;

const cards = [
  {
    title: 'Trust',
    description:
      'We are committed to transforming the way you do business; this means we are transparent, accountable, and reliable in all we do.',
  },
  {
    title: 'Open-minded',
    description:
      'Every organisation has its own unique requirements; we collaborate closely with our clients to tailor RCAD so they can get the most value from it.',
  },
  {
    title: 'We are a family',
    description:
      "Family is a core value for us because it helps us focus on the 'why' behind our product: creating positive experiences for everyone it touches. By remembering where we came from, we ensure we continue helping others' dreams come true.",
  },
];

const StyledValues = styled.div`
  margin-bottom: 200px;

  @media (max-width: 900px) {
    text-align: center;
  }
`;

const StyledHeading = styled.h1<{
  size: number;
}>`
  color: #fff;
  font-family: 'Helvetica Neue Roman';
  font-size: ${(props) => props.size}px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 32px;

  @media (max-width: 1440px) {
    font-size: ${(props) => (props.size * 2) / 3}px;
  }
`;

const StyledCards = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1440px;

  @media (max-width: 1440px) {
    margin-left: 0;
    width: 1200px;
  }

  @media (max-width: 1200px) {
    width: 900px;
  }

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1440px) {
    max-width: 1200px;
  }

  @media (max-width: 1200px) {
    max-width: 900px;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 1200px) {
    padding: 0 5%;
  }
`;

const StyledTitle = styled.h2`
  font-family: 'Helvetica Neue Roman';
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 86px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 60px;

  @media (max-width: 1440px) {
    font-size: 46px;
    line-height: 60px;
  }
`;

const StyledMission = styled.section`
  margin-bottom: 200px;
`;

const StyledCTA = styled.section`
  margin-top: 174px;
  margin-bottom: 200px;
  position: relative;
`;

const StyledCTAImage = styled.img`
  width: 100%;
`;

function AboutContainer() {
  useEffect(() => {
    document.title = `R-CAD - About`;
  }, []);

  return (
    <main>
      <Header />
      <StyledContainer>
        <FadeInSection>
          <StyledCTA>
            <StyledTitle>
              Bridging the gap between <br /> expectations and reality
            </StyledTitle>
            <StyledCTAImage src={cta} alt="" />
          </StyledCTA>
        </FadeInSection>
        <FadeInSection>
          <StyledMission>
            <StyledTitle>Our Mission</StyledTitle>
            <Subheading
              text="We empower builders and designers increase accuracy and save on design costs, helping them foster trust and loyalty with their customers by exceeding expectations."
              textStyles={{ width: 800, fontSize: 24, lineHeight: '35px' }}
            />
            <Subheading
              text="RCAD was created to deliver accurate, timely experiences for designers, architects, builders and their clients. 3D modelling and photo-realistic visualisation is transforming the way people design, build and buy real estate."
              vector={false}
              textStyles={{ width: 800, fontSize: 24, lineHeight: '35px' }}
            />
            <Subheading
              text="Too many vendors provide software that generate incomplete rendering, inaccurate scale, or unappealing designs. RCAD provides a complete, immersive virtual experience so that customers and project stakeholders 
can appreciate your design at true 1:1 scale, including photo-realistic lighting, 360° navigation, and unlimited customisability in customising fixtures and finishes from your predefined catalogue."
              vector={false}
              textStyles={{ width: 800, fontSize: 24, lineHeight: '35px' }}
            />
          </StyledMission>
        </FadeInSection>
        <FadeInSection>
          <StyledValues>
            <StyledHeading size={72} style={{ marginBottom: 112 }}>
              Our Values
            </StyledHeading>
            <StyledCards>
              {cards.map((card, idx) => (
                <ArchitectureCard
                  set={2}
                  key={card.title}
                  idx={idx}
                  title={card.title}
                  description={card.description}
                />
              ))}
            </StyledCards>
          </StyledValues>
        </FadeInSection>
      </StyledContainer>

      <FadeInSection>
        <StyledImage src={aboutBg} alt="" />
      </FadeInSection>

      <FadeInSection>
        <CallToAction
          title="Let's talk about your project"
          text="Improve planning accuracy, reduce costs, and provide your customers with an unforgettable virtual experience."
        />
      </FadeInSection>
      <Footer />
    </main>
  );
}

export default AboutContainer;
