import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ArchitectureCard,
  Button,
  FadeInSection,
  FeatureSet,
  Footer,
  Header,
  Subheading,
  CallToAction,
  FeatureSetProps,
} from '../components';
import { ContainerProps } from '../types';
import {
  StyledCards,
  StyledCardsContainer,
  StyledContainer,
  StyledCTA,
  StyledFirstSection,
  StyledHeading,
} from '../styles';
import {
  civilBg,
  civilFeature1,
  civilFeature2,
  civilFeature3,
} from '../images';

const cards = [
  {
    title: 'Realistic architectural rendering',
    description:
      'Tired of costly, inaccurate mock-ups leading to misinformed decisions and dissatisfied customers? RCAD extracts design specifications directly from your uploaded CAD drawings, making our models the most scale-precise digital renditions in the market.',
  },
  {
    title: 'Unforgettable virtual experiences',
    description:
      'Using industry-leading technology, RCAD provides compelling To-Scale experiences for your designers and customers. With fully rendered models, complete with ultra-realistic textures, scale and lighting, an RCAD VR headset lets you experience your design as if it was already built.',
  },
  {
    title: 'BIM & GIS integration',
    description:
      'Integrating RCAD with your BIM allows designers, project managers and architects to identify design issues before they manifest. GIS integration lets you accurately visualise your development in the context of its surroundings, including connecting roads and nearby amenities, through intelligent GIS integration.',
    icon: civilFeature3,
  },
];

const features: FeatureSetProps[] = [
  {
    image: civilFeature1,
    imagePosition: 'left',
    subtitle: 'PRODUCTIVITY',
    title: <span>Communicate complex project solutions</span>,
    description: (
      <>
        <span>
          RCAD's To-Scale models help you facilitate strategic partnerships,
          improve communication and involve stakeholders more closely in the
          project, allowing for better interaction and input into the planning
          and design stages.
        </span>
        <br />
        <br />
        <span>
          Forget lengthy meetings and theoretical debates — place your clients
          and stakeholders in the middle of your design so they can see it from
          every angle. With RCAD's VR headsets, every detail of a planned
          structure — be it a bridge, road, dam or tunnel — can be examined up
          close at 1:1 scale before it's constructed.
        </span>
      </>
    ),
  },
  {
    image: civilFeature2,
    imagePosition: 'right',
    subtitle: 'ACCURACY',
    title: (
      <span>
        Reduce project costs and accelerate completion time with intelligent
        modelling
      </span>
    ),
    description: (
      <>
        <span>
          RCAD recreates your project designs with centimetre-accuracy by
          drawing information straight from your CAD files. This leads to better
          decision-making, improved clash and error detection and decreased risk
          of budget overruns.
        </span>
        <br />
        <br />
        <span>
          With precise scale and rapidly reconfigurable detailing, RCAD's
          To-Scale infrastructure visualisations provide greater understanding
          of engineering design and construction processes, helping you realise
          projects faster, cheaper, and with greater accuracy than ever before.
        </span>
      </>
    ),
  },
];

function CivilInfrastructureContainer(props: ContainerProps) {
  const { title } = props;

  useEffect(() => {
    document.title = `R-CAD - ${title}`;
  }, [title]);

  return (
    <main>
      <Header />
      <section>
        <StyledFirstSection image={civilBg}>
          <StyledCTA>
            <StyledHeading size={150}>
              Civil <br /> Infrastructure
            </StyledHeading>
            <Subheading
              style={{ margin: 0 }}
              text="Improve planning accuracy with centimetre-accurate To-Scale models of planned infrastructure. Host interactive virtual tours for project stakeholders to facilitate buy-in and accelerate approval times."
            />
            <Link to="contact-us">
              <Button text="get started" />
            </Link>
          </StyledCTA>
        </StyledFirstSection>
        <StyledContainer>
          <FadeInSection>
            <StyledCardsContainer>
              <StyledHeading size={72} style={{ marginBottom: 140 }}>
                Bring your projects to life <br /> with a true sense of scale
              </StyledHeading>
              <StyledCards>
                {cards.map((card, idx) => (
                  <ArchitectureCard
                    key={card.title}
                    idx={idx}
                    title={card.title}
                    description={card.description}
                    icon={card.icon}
                  />
                ))}
              </StyledCards>
            </StyledCardsContainer>
          </FadeInSection>

          <FadeInSection>
            <StyledHeading size={72} style={{ margin: '200px 0 130px 0' }}>
              The future of <br /> infrastructure planning
            </StyledHeading>
          </FadeInSection>

          <FadeInSection>
            <>
              {features.map((feature) => (
                <FeatureSet
                  key={feature.subtitle}
                  title={feature.title}
                  description={feature.description}
                  image={feature.image}
                  imagePosition={feature.imagePosition}
                  subtitle={feature.subtitle}
                />
              ))}
            </>
          </FadeInSection>
        </StyledContainer>
        <FadeInSection>
          <CallToAction
            title="Ready to Get Started?"
            text={
              <>
                The first step to building smarter cities is to schedule a call
                with <br /> our team to discuss your unique requirements. We
                work closely <br /> with our clients to ensure RCAD is a perfect
                fit for your business.
              </>
            }
          />
        </FadeInSection>
        <FadeInSection>
          <Footer />
        </FadeInSection>
      </section>
    </main>
  );
}

export default CivilInfrastructureContainer;
