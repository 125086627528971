import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';
import Navigation from './Navigation';
import { StyledNavItem } from './NavItem';
import Button from './Button';
import { downArrow, closeIcon, hamburgerIcon, logo } from '../images';

const navItems = [
  {
    title: 'solutions',
    href: '/solutions',
    navItems: [
      { title: 'Architects', href: '/solutions/architects' },
      { title: 'Home-Builders', href: '/solutions/home-builders' },
      { title: 'Land Developers', href: '/solutions/land-developers' },
      {
        title: 'High-Rise Developers',
        href: '/solutions/high-rise-developers',
      },
      {
        title: 'Civil Infrastructure',
        href: '/solutions/civil-infrastructure',
      },
    ],
  },
  { title: 'about', href: '/about' },
];

const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;

  @media (max-width: 900px) {
    height: 70px;
  }

  > a {
    margin-left: 60px;

    @media (max-width: 1066px) {
      border: 1px solid #938880;
      height: 83px;
      align-items: center;
      display: flex;
      flex: 1;
      margin-left: 0;
      padding-left: 15px;
    }

    @media (max-width: 900px) {
      height: 70px;
    }
  }
`;

const StyledMobileNav = styled(StyledNavItem)`
  display: none;

  > * {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 1066px) {
    display: flex;
    border: 1px solid #938880;
    flex: 1;

    &:first-of-type {
      border-left: none;
      border-right: none;
    }
  }
`;

const StyledMobileNavContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #313133;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 150px 5% 0 5%;
`;

const StyledMobileTabName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d9c494;
  padding: 20px 0;
  border-top: 0.5px solid #938880;
  border-bottom: 0.5px solid #938880;
  width: 100%;
  justify-content: center;
  cursor: pointer;
`;

const StyledNavTab = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d9c494;
  padding: 20px 0;

  &::first-of-type {
    border-top: 0.5px solid #938880;
  }
  border-bottom: 0.5px solid #938880;
  width: 100%;
  justify-content: center;
  cursor: pointer;

  > a {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledLogo = styled.img`
  @media (max-width: 900px) {
    height: 30px;
  }
`;

function Header() {
  const [mobileNavHeight, setMobileNavHeight] = useState<number | string>(0);
  const [subNavHeight, setSubNavHeight] = useState<number | string>(0);

  const toggleMobileNavHeight = () => {
    setMobileNavHeight(mobileNavHeight === 0 ? 'auto' : 0);
  };

  return (
    <>
      <StyledHeader>
        <Link to="/">
          <StyledLogo src={logo} alt="logo" />
        </Link>

        <Navigation />

        <StyledMobileNav inverted={false}>
          <Link to="/contact-us"> Get Started</Link>
        </StyledMobileNav>
        <StyledMobileNav inverted={false} onClick={toggleMobileNavHeight}>
          <img
            src={mobileNavHeight === 0 ? hamburgerIcon : closeIcon}
            alt="Menu"
          />
        </StyledMobileNav>
      </StyledHeader>
      <AnimateHeight height={mobileNavHeight}>
        <StyledMobileNavContainer>
          {navItems.map((navItem) => {
            if (navItem.navItems) {
              return (
                <div
                  key={navItem.title}
                  style={{ width: '100%', cursor: 'pointer' }}
                  onClick={() =>
                    setSubNavHeight(subNavHeight === 0 ? 'auto' : 0)
                  }
                >
                  <StyledMobileTabName>
                    {navItem.title}
                    <img
                      style={{
                        marginLeft: 5,
                        width: 15,
                        transition: 'transform 200ms ease-out',
                        transform: `rotate(${
                          subNavHeight === 0 ? '0' : '180'
                        }deg)`,
                      }}
                      src={downArrow}
                      alt=""
                    />
                  </StyledMobileTabName>

                  <AnimateHeight
                    height={subNavHeight}
                    style={{
                      background: '#242425',
                    }}
                  >
                    {navItem.navItems.map((item) => (
                      <StyledNavTab key={item.title}>
                        <Link to={item.href}>{item.title}</Link>
                      </StyledNavTab>
                    ))}
                  </AnimateHeight>
                </div>
              );
            }

            return (
              <StyledNavTab key={navItem.title}>
                <Link to={navItem.href}>{navItem.title}</Link>
              </StyledNavTab>
            );
          })}
          <Link
            to="/contact-us"
            style={{
              width: '100%',
            }}
          >
            <Button
              text="Schedule a free demo"
              style={{
                marginTop: 50,
                width: '100%',
              }}
            />
          </Link>
        </StyledMobileNavContainer>
      </AnimateHeight>
    </>
  );
}

export default Header;
