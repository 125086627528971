import styled from 'styled-components';

export const StyledContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: 1440px) {
    max-width: 1200px;
  }

  @media (max-width: 1200px) {
    max-width: 900px;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 900px) {
    padding: 0 5%;
  }
`;

export const StyledHeading = styled.h1<{
  size: number;
}>`
  color: #fff;
  font-family: 'Helvetica Neue Roman';
  font-size: ${(props) => props.size}px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 32px;

  @media (max-width: 1440px) {
    font-size: ${(props) => (props.size * 2) / 3}px;
  }

  @media (max-width: 900px) {
    font-size: 48px;
    margin-bottom: 15px;
    text-align: left;

    ${(props) =>
      props.size === 72 &&
      `
        margin-bottom: 70px!important;
        margin-top: 70px!important;
    `}
  }
`;

export const StyledCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 330px);
  grid-gap: 76px;
  margin-left: 245px;

  @media (max-width: 1440px) {
    margin-left: 0;
    width: 1200px;
  }

  @media (max-width: 1200px) {
    width: 900px;
    grid-template-columns: repeat(3, 250px);
  }

  @media (max-width: 900px) {
    width: 100%;
    grid-template-columns: 1fr;
    align-items: center;
  }
`;

export const StyledCardsContainer = styled.div`
  @media (max-width: 900px) {
    text-align: center;
  }
`;

export const StyledCTA = styled.div`
  transform: translateY(calc(((100vh - 83px) - 874px) / 2));
  position: absolute;
  left: 240px;
  max-width: 700px;

  @media (max-width: 1200px) {
    left: 120px;
  }

  @media (max-width: 900px) {
    left: 0;
    text-align: center;
    position: initial;
    transform: none;

    p,
    h1 {
      text-align: left;
    }
  }

  > a > button {
    margin-top: 40px;
    margin-left: 140px;

    @media (max-width: 900px) {
      margin-left: 0;
    }
  }
`;

export const StyledFirstSection = styled.section<{
  image: string;
}>`
  ${(props) =>
    props.image &&
    `
  background: url(${props.image});
  `}
  background-repeat: no-repeat;
  background-position: right bottom;
  height: calc(100vh - 83px);
  margin-bottom: 200px;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    ${(props) =>
      props.image &&
      `
      background: linear-gradient(
        180deg,
        rgba(49, 49, 51, 0) 1.56%,
        #313133 100%), url(${props.image});
      background-position: top center;
      background-size: 100% 220px;
      background-repeat: no-repeat;
    `}

    padding: 0 5%;
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    height: auto;
    padding-top: 150px;
    margin-bottom: 20px;
  }
`;
