import styled from 'styled-components';
import { card1, card2, card3, card4, card5, card6 } from '../images';

interface ArchitectureCardProps {
  title: string;
  description: string;
  idx: number;
  set?: 1 | 2;
  icon?: string;
}

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 330px;

  @media (max-width: 1200px) {
    width: 250px;
  }

  @media (max-width: 900px) {
    width: 100%;
    text-align: left;
    align-items: flex-start;
  }
`;

const StyledTitle = styled.h3`
  margin: 50px 0 10px 0;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
`;

const StyledDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
`;

const images = [card1, card2, card3, card4, card5, card6];

function ArchitectureCard(props: ArchitectureCardProps) {
  const { title, description, idx, set = 1, icon } = props;

  const iconSrc = icon ? icon : set === 1 ? images[idx] : images[idx + 3];

  return (
    <StyledCard>
      <img src={iconSrc} alt="" />
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{description}</StyledDescription>
    </StyledCard>
  );
}

export default ArchitectureCard;
