import { CSSProperties } from 'react';
import styled from 'styled-components';

interface SubheadingProps {
  text: string | JSX.Element;
  vector?: boolean;
  style?: CSSProperties;
  textStyles?: CSSProperties;
}

const StyledSubheading = styled.div`
  display: flex;
  z-index: 1;
  margin-bottom: 25px;

  > p {
    color: #fff;
    opacity: 0.6;
    width: 440px;
    margin: 0;
    line-height: 24px;

    /* @media (max-width: 1440px) {
      width: 300px;
    } */

    @media (max-width: 1200px) {
      width: auto !important;
    }

    @media (max-width: 900px) {
      text-align: left;
    }
  }
`;

const StyledVector = styled.div<{
  visible: boolean;
}>`
  width: 129px;
  height: 1px;
  background: #fff;
  opacity: 0.6;
  margin-right: 16px;
  margin-top: 10px;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  @media (max-width: 1440px) {
    display: none;
  }

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 18px;
  }
`;

function Subheading(props: SubheadingProps) {
  const { text, style = {}, textStyles = {}, vector = true } = props;

  return (
    <StyledSubheading style={style}>
      <StyledVector visible={vector} />
      <p style={textStyles}>{text}</p>
    </StyledSubheading>
  );
}

export default Subheading;
