import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ArchitectureCard,
  Button,
  FadeInSection,
  FeatureSet,
  Footer,
  Header,
  Subheading,
  CallToAction,
  FeatureSetProps,
} from '../components';
import { ContainerProps } from '../types';
import {
  StyledCards,
  StyledCardsContainer,
  StyledContainer,
  StyledCTA,
  StyledFirstSection,
  StyledHeading,
} from '../styles';
import { highRiseBg, highRiseFeature1, highRiseFeature2 } from '../images';

const cards = [
  {
    title: 'Realistic architectural rendering',
    description:
      'Tired of costly, inaccurate mock-ups leading to misinformed decisions and dissatisfied customers? RCAD extracts design specifications directly from your uploaded CAD drawings, making our models the most scale-precise digital renditions in the market.',
  },
  {
    title: 'Unforgettable virtual experiences',
    description:
      'Using industry-leading technology, RCAD provides compelling To-Scale experiences for your designers and customers. With fully rendered models, complete with ultra-realistic textures, scale and lighting, an RCAD VR headset lets you experience your design as if it was already built.',
  },
  {
    title: 'Interactive inventory catalogue',
    description:
      'We work closely with our clients to configure a bespoke digital asset library from their specific catalogues, allowing users to transform their surroundings with a single click. Tailor everything from tiles, door handles, furniture, fixtures, paint colour – even which paintings to hang on the walls.',
  },
];

const features: FeatureSetProps[] = [
  {
    image: highRiseFeature1,
    imagePosition: 'left',
    subtitle: 'EXPERIENCE',
    title: (
      <span>
        Take a virtual tour <br /> in the clouds
      </span>
    ),
    description: (
      <>
        <span>
          Buying an apartment off the plan can be an intimidating prospect.
          Build trust and loyalty with potential buyers by showcasing your
          apartment designs with RCAD's photo-realistic To-Scale modelling.
        </span>
        <br />
        <br />
        <span>
          Using RCAD's top-of-the-line VR headsets, buyers can set foot in their
          apartment before construction has even begun, and easily customise
          their surroundings from your preconfigured catalogue of finishes.
        </span>
        <br />
        <br />
        <span>
          Want to know what the view from the 32nd floor looks like? At sunrise
          and at sunset? You can see that too.
        </span>
      </>
    ),
  },
  {
    image: highRiseFeature2,
    imagePosition: 'right',
    subtitle: 'FLEXIBILITY',
    title: (
      <span>
        Design smarter <br /> and faster
      </span>
    ),
    description: (
      <>
        <span>
          RCAD's configurability allows designers and engineers to make
          real-time adjustments to their architectural designs – from changing
          the wallpaper to adding an extra floor – and visualise the changes in
          an interactive To-Scale model, including the building's surroundings.
        </span>
        <br />
        <br />
        <span>
          GIS & BIM integration brings a holistic approach to design &
          construction, helping you save time on approvals and improve
          stakeholder communication by showcasing your ideas through a fully
          rendered, interactive, 1:1 scale model of your project
        </span>
      </>
    ),
  },
];

function HighRiseBuildersContainer(props: ContainerProps) {
  const { title } = props;

  useEffect(() => {
    document.title = `R-CAD - ${title}`;
  }, [title]);

  return (
    <main>
      <Header />
      <section>
        <StyledFirstSection image={highRiseBg}>
          <StyledCTA>
            <StyledHeading size={150}>
              High-Rise <br /> Builders
            </StyledHeading>
            <Subheading
              style={{ margin: 0 }}
              text="Get a photo-realistic impression of what your construction will look, including the ability to customise finishes based on your interactive catalogue."
            />
            <Link to="contact-us">
              <Button text="get started" />
            </Link>
          </StyledCTA>
        </StyledFirstSection>
        <StyledContainer>
          <FadeInSection>
            <StyledCardsContainer>
              <StyledHeading size={72} style={{ marginBottom: 140 }}>
                The sky is <br /> no longer the limit
              </StyledHeading>
              <StyledCards>
                {cards.map((card, idx) => (
                  <ArchitectureCard
                    key={card.title}
                    idx={idx}
                    title={card.title}
                    description={card.description}
                  />
                ))}
              </StyledCards>
            </StyledCardsContainer>
          </FadeInSection>

          <FadeInSection>
            <StyledHeading size={72} style={{ margin: '200px 0 130px 0' }}>
              Captivate buyers with <br /> next-level engagement
            </StyledHeading>
          </FadeInSection>

          <FadeInSection>
            <>
              {features.map((feature) => (
                <FeatureSet
                  key={feature.subtitle}
                  title={feature.title}
                  description={feature.description}
                  image={feature.image}
                  imagePosition={feature.imagePosition}
                  subtitle={feature.subtitle}
                />
              ))}
            </>
          </FadeInSection>
        </StyledContainer>
        <FadeInSection>
          <CallToAction
            title="Ready to Get Started?"
            text={
              <span>
                Streamline your design process and take your buyers <br /> on a
                journey they'll never forget with RCAD's unique <br />{' '}
                interactive virtual tours.
              </span>
            }
          />
        </FadeInSection>
        <FadeInSection>
          <Footer />
        </FadeInSection>
      </section>
    </main>
  );
}

export default HighRiseBuildersContainer;
