import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';
import CrossfadeImage from 'react-crossfade-image';
import Button from './Button';
import Subheading from './Subheading';
import {
  architectsImage,
  civilInfraImage,
  downArrow,
  experienceBg,
  highRiseBuildersImage,
  homeBuildersImage,
  landDevelopersImage,
} from '../images';

const tabs = [
  {
    title: 'Home Builders',
    href: '/solutions/home-builders',
    description:
      'Enjoy a fully integrated catalogue of fixtures and finishes that you can customise in real-time, providing your customers with an immersive, interactive home buying experience.',
    image: homeBuildersImage,
  },
  {
    title: 'Land Developers',
    href: '/solutions/land-developers',
    description:
      "Visualise entire unbuilt estates including parks, paths, roads and amenities – get a bird's eye view, or take a virtual walk through your land parcels to see what they will look like once developed.",
    image: landDevelopersImage,
  },
  {
    title: 'High-Rise Builders',
    href: '/solutions/high-rise-developers',
    description:
      'Captivate buyers with next-level engagement. Get a photo-realistic impression of what your construction will look, including the ability to customise finishes based on your interactive catalogue.',
    image: highRiseBuildersImage,
  },
  {
    title: 'Civil Infrastructure',
    href: '/solutions/civil-infrastructure',
    description:
      'Improve planning accuracy with centimetre-accurate To-Scale models of planned infrastructure. Host interactive virtual tours for project stakeholders to facilitate buy-in and accelerate approval times.',
    image: civilInfraImage,
  },
  {
    title: 'Architects',
    href: '/solutions/architects',
    description:
      'Execute your vision to perfection by making design changes in real-time. RCAD interacts seamlessly with CAD files, reducing labour and improving cost estimation.',
    image: architectsImage,
  },
];

const StyledScreen = styled.div`
  margin-top: 100vh;
  display: flex;
  align-items: center;
  background: url(${experienceBg});
  background-repeat: no-repeat;
  background-position: bottom right;
  margin-bottom: 200px;
  padding-top: 100px;

  @media (max-height: 1000px) {
    padding-top: 200px;
  }

  @media (max-height: 900px) {
    padding-top: 300px;
  }

  @media (max-height: 800px) {
    padding-top: 400px;
  }

  @media (max-height: 700px) {
    padding-top: 500px;
  }

  @media (max-width: 900px) {
    background: none;
    padding: 0 5%;
    margin-top: 0;
    margin-bottom: 90px;
  }
`;

const StyledContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: 1440px) {
    max-width: 1280px;
  }

  @media (max-width: 1280px) {
    max-width: 900px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const StyledHeading = styled.h1`
  color: #fff;
  font-family: 'Helvetica Neue Roman';
  font-size: 72px;
  line-height: 86px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 32px;

  @media (max-width: 1440px) {
    font-size: 46px;
    line-height: 60px;
  }
`;

const StyledTabs = styled.div`
  margin-right: 50px;

  @media (max-width: 900px) {
    margin: 0;
    width: 100%;
  }
`;

const StyledTabHeading = styled.div`
  font-family: 'Helvetica Neue Roman';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #d9c494;
  margin-bottom: 55px;
`;

const StyledTab = styled.div<{
  active: boolean;
}>`
  font-family: 'Helvetica Neue Roman';
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 30px;
  cursor: pointer;
  color: ${(props) =>
    props.active ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.6)'};

  @media (max-width: 1440px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledMobileTabs = styled.div`
  display: none;

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

const StyledDescription = styled.div`
  margin-left: 60px;
  max-width: 550px;

  > div {
    margin: 0;
  }

  @media (max-width: 900px) {
    margin: 0;
  }
`;

const StyledImage = styled.img`
  @media (max-width: 1440px) {
    width: 400px;
  }

  @media (max-width: 900px) {
    height: 180px;
    width: 100%;
    object-fit: cover;
  }
`;

const StyledMobileTabName = styled.div`
  font-family: 'Helvetica Neue Roman';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #ffffff;
  height: 62px;
  width: 110%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  > img {
    filter: grayscale();
  }
`;

const StyledContent = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    display: none;
  }
`;

function ExperienceFor() {
  const [activeTab, setActiveTab] = useState(0);
  const [mobileTabHeights, setMobileTabHeights] = useState<
    Record<string, number | string>
  >(() => tabs.reduce((acc, curr) => ({ ...acc, [curr.title]: 0 }), {}));

  const onTabClick = (idx: number) => {
    setActiveTab(idx);
  };

  const toggleHeight = (tab: string) => {
    setMobileTabHeights({
      ...mobileTabHeights,
      [tab]: mobileTabHeights[tab] === 0 ? 'auto' : 0,
    });
  };

  return (
    <StyledScreen>
      <StyledContainer>
        <StyledTabs>
          <StyledTabHeading>New experiences for…</StyledTabHeading>

          {tabs.map((tab, idx) => (
            <Fragment key={tab.title}>
              <StyledTab
                active={idx === activeTab}
                onClick={() => onTabClick(idx)}
              >
                {tab.title}
              </StyledTab>
              <StyledMobileTabs onClick={() => toggleHeight(tab.title)}>
                <StyledMobileTabName>
                  <span
                    style={{
                      transition: 'color 200ms ease-out',
                      color:
                        mobileTabHeights[tab.title] === 0
                          ? 'rgba(255, 255, 255, 0.6)'
                          : '#fff',
                    }}
                  >
                    {tab.title}
                  </span>{' '}
                  <img
                    style={{
                      transition: 'transform 200ms ease-out',
                      transform: `rotate(${
                        mobileTabHeights[tab.title] === 0 ? '0' : '180'
                      }deg)`,
                    }}
                    src={downArrow}
                    alt=""
                  />
                </StyledMobileTabName>
                <AnimateHeight height={mobileTabHeights[tab.title]}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                      padding: 10,
                    }}
                  >
                    <StyledImage src={tab.image} alt="" />
                    <StyledDescription>
                      <StyledHeading>{tab.title}</StyledHeading>
                      <Subheading
                        text={tab.description}
                        textStyles={{ textAlign: 'left' }}
                      />
                      <Link to={tab.href}>
                        <Button
                          text="Learn More"
                          style={{ marginTop: 40, marginBottom: 115 }}
                        />
                      </Link>
                    </StyledDescription>
                  </div>
                </AnimateHeight>
              </StyledMobileTabs>
            </Fragment>
          ))}
        </StyledTabs>

        <StyledContent>
          <CrossfadeImage
            duration={500}
            src={tabs[activeTab].image}
            alt={tabs[activeTab].title}
          />
          <StyledDescription>
            <StyledHeading>{tabs[activeTab].title}</StyledHeading>
            <Subheading text={tabs[activeTab].description} />
            <Link to={tabs[activeTab].href}>
              <Button
                text="Learn More"
                style={{ marginTop: 40, marginLeft: 136 }}
              />
            </Link>
          </StyledDescription>
        </StyledContent>
      </StyledContainer>
    </StyledScreen>
  );
}

export default ExperienceFor;
