import styled from 'styled-components';

interface FeedbackProps {
  photo: string;
  title: string;
  subtitle: string;
  text: string;
}

const StyledFeedback = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 460px;

  @media (max-width: 1200px) {
    width: auto;
  }
`;

const StyledText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 35px;
`;

const StyledAuthor = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPhoto = styled.img`
  border-radius: 50%;
  margin-right: 15px;
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyleTitle = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin: 0;
`;

const StyledSubtitle = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0.6;
`;

function Feedback(props: FeedbackProps) {
  const { photo, title, subtitle, text } = props;

  return (
    <StyledFeedback>
      <StyledText>{text}</StyledText>
      <StyledAuthor>
        <StyledPhoto src={photo} alt={title} />
        <StyledInfo>
          <StyleTitle>{title}</StyleTitle>
          <StyledSubtitle>{subtitle}</StyledSubtitle>
        </StyledInfo>
      </StyledAuthor>
    </StyledFeedback>
  );
}

export default Feedback;
