import styled from 'styled-components';
import { arrow } from '../images';

const StyledButton = styled.button`
  display: none;

  @media (max-width: 900px) {
    display: block;
    cursor: pointer;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: transparent;
    transform: rotate(180deg);
    margin: 0 auto;
  }
`;

function ContinueScrolling() {
  return (
    <StyledButton>
      <img src={arrow} alt="Continue scrolling" />
    </StyledButton>
  );
}

export default ContinueScrolling;
