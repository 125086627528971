const CLOUDFRONT_DOMAIN = 'https://dqhj3xuqcms6c.cloudfront.net/';

export const homeImage1 = `${CLOUDFRONT_DOMAIN}home-screen-01.jpg`;
export const homeImage2 = `${CLOUDFRONT_DOMAIN}home-screen-02.jpg`;
export const card1 = `${CLOUDFRONT_DOMAIN}card-1.svg`;
export const card2 = `${CLOUDFRONT_DOMAIN}card-2.svg`;
export const card3 = `${CLOUDFRONT_DOMAIN}card-3.svg`;
export const card4 = `${CLOUDFRONT_DOMAIN}card-4.svg`;
export const card5 = `${CLOUDFRONT_DOMAIN}card-5.svg`;
export const card6 = `${CLOUDFRONT_DOMAIN}card-6.svg`;
export const leftImage = `${CLOUDFRONT_DOMAIN}footer-1.svg`;
export const rightImage = `${CLOUDFRONT_DOMAIN}footer-2.svg`;
export const mobileImage = `${CLOUDFRONT_DOMAIN}footer-3.svg`;
export const arrow = `${CLOUDFRONT_DOMAIN}top-arrow.svg`;
export const exampleImg = `${CLOUDFRONT_DOMAIN}example.png`;
export const leftArrow = `${CLOUDFRONT_DOMAIN}left-arrow.svg`;
export const rightArrow = `${CLOUDFRONT_DOMAIN}right-arrow.svg`;
export const experienceBg = `${CLOUDFRONT_DOMAIN}home-screen-04.png`;
export const architectsImage = `${CLOUDFRONT_DOMAIN}architects.jpg`;
export const landDevelopersImage = `${CLOUDFRONT_DOMAIN}land-developers.jpg`;
export const homeBuildersImage = `${CLOUDFRONT_DOMAIN}home-builders.jpg`;
export const highRiseBuildersImage = `${CLOUDFRONT_DOMAIN}high-rise-builders.jpg`;
export const civilInfraImage = `${CLOUDFRONT_DOMAIN}civil-infra.jpg`;
export const downArrow = `${CLOUDFRONT_DOMAIN}down-arrow.svg`;
export const logo = `${CLOUDFRONT_DOMAIN}logo.svg`;
export const instagramIcon = `${CLOUDFRONT_DOMAIN}instagram.svg`;
export const facebookIcon = `${CLOUDFRONT_DOMAIN}facebook.svg`;
export const linkedInIcon = `${CLOUDFRONT_DOMAIN}linkedin.svg`;
export const hamburgerIcon = `${CLOUDFRONT_DOMAIN}hamburger.svg`;
export const closeIcon = `${CLOUDFRONT_DOMAIN}close-icon.svg`;
export const upload = `${CLOUDFRONT_DOMAIN}upload.svg`;
export const open = `${CLOUDFRONT_DOMAIN}open.svg`;
export const invite = `${CLOUDFRONT_DOMAIN}invite.svg`;
export const o1 = `${CLOUDFRONT_DOMAIN}01.svg`;
export const o2 = `${CLOUDFRONT_DOMAIN}02.svg`;
export const o3 = `${CLOUDFRONT_DOMAIN}03.svg`;
export const checkIcon = `${CLOUDFRONT_DOMAIN}check.svg`;
export const learnMore = `${CLOUDFRONT_DOMAIN}learn-more.svg`;
export const feedback1Img = `${CLOUDFRONT_DOMAIN}feedback-1.png`;
export const feedback2Img = `${CLOUDFRONT_DOMAIN}feedback-2.png`;
export const quotesIcon = `${CLOUDFRONT_DOMAIN}double-quotes.svg`;
export const aboutBg = `${CLOUDFRONT_DOMAIN}about-bg.jpg`;
export const cta = `${CLOUDFRONT_DOMAIN}about-cta.jpg`;
export const starIcon = `${CLOUDFRONT_DOMAIN}star.svg`;
export const architectsFeature1 = `${CLOUDFRONT_DOMAIN}architects-1.svg`;
export const architectsFeature2 = `${CLOUDFRONT_DOMAIN}architects-2.svg`;
export const architectsBg = `${CLOUDFRONT_DOMAIN}architects-bg.jpg`;
export const civilFeature1 = `${CLOUDFRONT_DOMAIN}civil-infra-1.svg`;
export const civilFeature2 = `${CLOUDFRONT_DOMAIN}civil-infra-2.svg`;
export const civilFeature3 = `${CLOUDFRONT_DOMAIN}civil-infra-feature-3.svg`;
export const civilBg = `${CLOUDFRONT_DOMAIN}civil-infra-bg.jpg`;
export const contactBg = `${CLOUDFRONT_DOMAIN}contact_us.png`;
export const highRiseFeature1 = `${CLOUDFRONT_DOMAIN}high-rise-builders-1.svg`;
export const highRiseFeature2 = `${CLOUDFRONT_DOMAIN}high-rise-builders-2.svg`;
export const highRiseBg = `${CLOUDFRONT_DOMAIN}high-rise-builders-bg.jpg`;
export const homeFeature1 = `${CLOUDFRONT_DOMAIN}home-builders-1.svg`;
export const homeFeature2 = `${CLOUDFRONT_DOMAIN}home-builders-2.svg`;
export const homeBg = `${CLOUDFRONT_DOMAIN}home-builders-bg.jpg`;
export const landFeature1 = `${CLOUDFRONT_DOMAIN}land-developers-1.svg`;
export const landFeature2 = `${CLOUDFRONT_DOMAIN}land-developers-2.svg`;
export const landFeature3 = `${CLOUDFRONT_DOMAIN}civil-infra-feature-3.svg`;
export const landBg = `${CLOUDFRONT_DOMAIN}land-developers-bg.jpg`;
