import styled from 'styled-components';
import { invite, o1, o2, o3, open, upload } from '../images';
import UsageCard from './UsageCard';

const StyledScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 300px;

  @media (max-width: 900px) {
    padding: 0 5%;
  }
`;

const StyledHeading = styled.h1`
  color: #fff;
  font-family: 'Helvetica Neue Roman';
  font-size: 72px;
  line-height: 86px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 60px;

  @media (max-width: 1440px) {
    font-size: 46px;
    line-height: 60px;
  }
`;

const StyledCards = styled.div`
  display: flex;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const StyledContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    max-width: 900px;
  }
`;

function HowItWorks() {
  return (
    <StyledScreen>
      <StyledContainer>
        <StyledHeading>How it works</StyledHeading>
        <StyledCards>
          <UsageCard
            title={
              <span>
                Upload <br /> CAD file
              </span>
            }
            description="The RCAD platform automatically builds a realistic, fully rendered To-Scale model based on your technical drawing, including structural components."
            icon={upload}
            positionIcon={o1}
          />
          <UsageCard
            title={
              <span>
                Configure <br /> meta-data
              </span>
            }
            description="We work closely with our clients to configure a bespoke digital asset library from their specific catalogues, allowing users to transform their surroundings with a single click."
            icon={open}
            positionIcon={o2}
          />
          <UsageCard
            title={
              <span>
                Immersive virtual <br /> experience
              </span>
            }
            description="Use RCAD's VR headset to take an immersive tour of your 1:1 scale model. With photo-realistic lighting and intuitive controls, you can explore every part of your design and make changes in real-time."
            icon={invite}
            positionIcon={o3}
          />
        </StyledCards>
      </StyledContainer>
    </StyledScreen>
  );
}

export default HowItWorks;
