import { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { downArrow } from '../images';
import { NavigationItem } from '../types';

interface NavItemProps {
  href: string;
  title: string;
  inverted: boolean;
  navItems?: NavigationItem[];
}

const StyledAnchor = styled(Link)`
  text-decoration: none;
`;

export const StyledNavItem = styled.div<{ inverted: boolean }>`
  cursor: pointer;
  width: 176px;
  height: 83px;
  color: ${(props) =>
    props.inverted ? props.theme.textSecondary : props.theme.textPrimary};
  background: ${(props) =>
    props.inverted ? props.theme.bgSecondary : props.theme.bgPrimary};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: ${(props) => (props.inverted ? 500 : 'normal')};

  ${(props) =>
    !props.inverted &&
    `
    border: 1px solid ${props.theme.borderPrimary};
    border-right-width: 0px;
  `}

  &:hover {
    background: ${(props) =>
      props.inverted ? props.theme.bgSecondaryHover : props.theme.bgPrimary};
  }

  &:active {
    color: ${(props) =>
      props.inverted
        ? props.theme.textSecondary
        : props.theme.textPrimaryActive};
    background: ${(props) =>
      props.inverted ? props.theme.bgSecondaryActive : props.theme.bgPrimary};
  }

  @media (max-width: 900px) {
    height: 70px;
  }
`;

const StyledDropdown = styled.div``;

const StyledHoverNav = styled.div<{
  visible: boolean;
}>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  top: 83px;
  width: 320px;
  padding: 20px 0;
  background: #2e2e2e;
  border: 0.5px solid #938880;
  transition: all 2s linear;
  height: ${(props) => (props.visible ? 250 : 0)};
`;

const StyledHoverMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledHoverNavItem = styled.div`
  color: ${(props) => props.theme.textPrimary};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Inter';
  font-size: 14px;
  padding: 10px 42px;

  &:hover {
    background: #212121;
  }
`;

function NavItem(props: NavItemProps) {
  const { href, title, inverted, navItems } = props;

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsHovering(true);
  };

  const handleMouseLeave = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsHovering(false);
  };

  if (navItems !== undefined) {
    const hoverNav = (
      <div>
        {navItems.map((navItem) => (
          <StyledAnchor key={navItem.href} to={navItem.href}>
            <StyledHoverNavItem>{navItem.title}</StyledHoverNavItem>
          </StyledAnchor>
        ))}
      </div>
    );

    return (
      <StyledHoverMenu
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <StyledDropdown onMouseEnter={handleMouseEnter}>
          <StyledNavItem inverted={inverted}>
            <span>{title}</span>
            <img style={{ marginLeft: 5 }} src={downArrow} alt="Dropdown" />
          </StyledNavItem>
        </StyledDropdown>
        <StyledHoverNav visible={isHovering}>{hoverNav}</StyledHoverNav>
      </StyledHoverMenu>
    );
  }

  return (
    <StyledAnchor to={href}>
      <StyledNavItem inverted={inverted}>{title}</StyledNavItem>
    </StyledAnchor>
  );
}

export default NavItem;
