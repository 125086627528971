import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ArchitectureCard,
  Button,
  FadeInSection,
  FeatureSet,
  Footer,
  Header,
  Subheading,
  CallToAction,
  FeatureSetProps,
} from '../components';
import { ContainerProps } from '../types';
import {
  StyledCards,
  StyledCardsContainer,
  StyledContainer,
  StyledCTA,
  StyledFirstSection,
  StyledHeading,
} from '../styles';
import { landBg, landFeature1, landFeature2, landFeature3 } from '../images';

const cards = [
  {
    title: 'Realistic architectural rendering',
    description:
      'Tired of costly, inaccurate mock-ups leading to misinformed decisions and dissatisfied customers? RCAD extracts design specifications directly from your uploaded CAD drawings, making our models the most scale-precise digital renditions in the market.',
  },
  {
    title: 'Unforgettable virtual experiences',
    description:
      'Using industry-leading technology, RCAD provides compelling To-Scale experiences for your designers and customers. With fully rendered models, complete with ultra-realistic textures, scale and lighting, an RCAD VR headset lets you experience your design as if it was already built.',
  },
  {
    title: 'BIM & GIS integration',
    description:
      'Integrating RCAD with your BIM allows designers, project managers and architects to identify design issues before they manifest. GIS integration lets you accurately visualise your development in the context of its surroundings, including connecting roads and nearby amenities, through intelligent GIS integration.',
    icon: landFeature3,
  },
];

const features: FeatureSetProps[] = [
  {
    image: landFeature1,
    imagePosition: 'left',
    subtitle: 'ACCURACY',
    title: 'Boost operational efficiency',
    description: (
      <>
        <span>
          Effectively communicating with your team and on-boarding new
          contractors has never been easier than with a live, realistic,
          To-Scale visualisation of your planned estate.
        </span>
        <br />
        <br />
        <span>
          Increase the efficiency of construction and save painful headaches of
          inaccurately executed work by having a centimetre-precise virtual
          rendition of your entire project plan.
        </span>
      </>
    ),
  },
  {
    image: landFeature2,
    imagePosition: 'right',
    subtitle: 'EXPERIENCE',
    title: 'Offer a compelling journey for buyers',
    description: (
      <>
        <span>
          Tired of trying to make a patch of dirt sound exciting? Show your
          customers their new neighbourhood with RCAD's advanced, To-Scale
          property visualisation.
        </span>
        <br />
        <br />
        <span>
          With realistic rendering, including bespoke foliage, amenities, and
          even the weather, take buyers on an augmented stroll or go for a
          bird's-eye tour of the planned estate, including gardens, roads,
          pathwalks and infrastructure – if it's in your architectural drawings,
          RCAD will model it!
        </span>
      </>
    ),
  },
];

function LandDevelopersContainer(props: ContainerProps) {
  const { title } = props;

  useEffect(() => {
    document.title = `R-CAD - ${title}`;
  }, [title]);

  return (
    <main>
      <Header />
      <section>
        <StyledFirstSection image={landBg}>
          <StyledCTA>
            <StyledHeading size={150}>
              Land <br /> Developers
            </StyledHeading>
            <Subheading
              style={{ margin: 0 }}
              text="Visualise entire unbuilt estates including parks, paths, roads and amenities — get a bird's eye view, or take a virtual walk through your land parcels to see what they will look like once developed."
            />
            <Link to="contact-us">
              <Button text="get started" />
            </Link>
          </StyledCTA>
        </StyledFirstSection>
        <StyledContainer>
          <FadeInSection>
            <StyledCardsContainer>
              <StyledHeading size={72} style={{ marginBottom: 140 }}>
                Showcase your vision, <br /> down to every blade <br /> of grass
              </StyledHeading>
              <StyledCards>
                {cards.map((card, idx) => (
                  <ArchitectureCard
                    key={card.title}
                    idx={idx}
                    title={card.title}
                    description={card.description}
                    icon={card.icon}
                  />
                ))}
              </StyledCards>
            </StyledCardsContainer>
          </FadeInSection>

          <FadeInSection>
            <StyledHeading size={72} style={{ margin: '200px 0 130px 0' }}>
              Make better business <br /> decisions through precise <br />{' '}
              digital modelling
            </StyledHeading>
          </FadeInSection>

          <FadeInSection>
            <>
              {features.map((feature) => (
                <FeatureSet
                  key={feature.subtitle}
                  title={feature.title}
                  description={feature.description}
                  image={feature.image}
                  imagePosition={feature.imagePosition}
                  subtitle={feature.subtitle}
                />
              ))}
            </>
          </FadeInSection>
        </StyledContainer>

        <FadeInSection>
          <CallToAction
            title="Ready to Get Started?"
            text="Streamline your development and off-the-plan sales processes through use of intelligent, accurate To-Scale models. Find out how you can transform your business today."
          />
        </FadeInSection>
        <FadeInSection>
          <Footer />
        </FadeInSection>
      </section>
    </main>
  );
}

export default LandDevelopersContainer;
