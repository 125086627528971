import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ScrollTop } from './components';
import {
  AboutContainer,
  ArchitectsContainer,
  CivilInfrastructureContainer,
  ContactUsContainer,
  HighRiseBuildersContainer,
  HomeBuildersContainer,
  HomeContainer,
  LandDevelopersContainer,
} from './containers';
import './index.css';

const theme = {
  bgPrimary: '#313133',
  bgSecondary: '#BFAF87',
  bgSecondaryHover: '#D6C395',
  bgSecondaryActive: '#B9A983',
  bgSecondaryDisabled: '#BFAF87',
  textSecondary: '#313133',
  textPrimary: '#D9C494',
  textPrimaryActive: '#B9A983',
  borderPrimary: '#938880',
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollTop />
      <ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/">
            <HomeContainer />
          </Route>
          <Route path="/contact-us">
            <ContactUsContainer />
          </Route>
          <Route path="/about">
            <AboutContainer />
          </Route>
          <Route path="/solutions/architects">
            <ArchitectsContainer title="Architects" />
          </Route>
          <Route path="/solutions/home-builders">
            <HomeBuildersContainer title="Home Builders" />
          </Route>
          <Route path="/solutions/land-developers">
            <LandDevelopersContainer title="Land Developers" />
          </Route>
          <Route path="/solutions/high-rise-developers">
            <HighRiseBuildersContainer title="High-Rise Developers" />
          </Route>
          <Route path="/solutions/civil-infrastructure">
            <CivilInfrastructureContainer title="Civil Infrastructure" />
          </Route>
        </Switch>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
