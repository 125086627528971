import { CSSProperties } from 'react';
import styled from 'styled-components';

interface ButtonProps {
  text: string;
  style?: CSSProperties;
  type?: 'button' | 'submit' | 'reset';
}

const StyledButton = styled.button`
  width: 181px;
  height: 60px;
  background: #bfaf87;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #313133;
  text-align: center;
  border: none;
  cursor: pointer;

  &:hover {
    background: #d6c395;
  }

  &:active {
    background: #b9a983;
  }

  @media (max-width: 1440px) {
    margin-left: 0 !important;
  }
`;

function Button(props: ButtonProps) {
  const { text, style, type } = props;

  return (
    <StyledButton type={type} style={style}>
      {text}
    </StyledButton>
  );
}

export default Button;
