import { useEffect } from 'react';
import styled from 'styled-components';
import {
  Header,
  HomeIntro,
  ExperienceFor,
  HowItWorks,
  Footer,
  FadeInSection,
  CallToAction,
} from '../components';

const StyledHomePage = styled.section`
  position: relative;
`;

function HomeContainer() {
  useEffect(() => {
    document.title = `R-CAD`;
  }, []);

  return (
    <StyledHomePage>
      <section>
        <Header />
        <HomeIntro />

        <FadeInSection>
          <ExperienceFor />
        </FadeInSection>

        <FadeInSection>
          <HowItWorks />
        </FadeInSection>

        <FadeInSection>
          <CallToAction
            title="Let's talk about your project"
            text="We work closely with our customers to ensure RCAD is the best fit for their business. Schedule an advisory consultation to discuss your requirements."
          />
        </FadeInSection>

        <FadeInSection>
          <Footer />
        </FadeInSection>
      </section>
    </StyledHomePage>
  );
}

export default HomeContainer;
